<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import repsDetails from "@/components/reps/repsDetails.vue";

/**
 * Starter component
 */
export default {
    page: {
        title: "Rep Details",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, repsDetails },
    data() {
        return {
            title: "Rep Details",
            items: [
                {
                    text: "Rep Details",
                    active: true,
                },
            ],
        };
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <repsDetails />
    </Layout>
</template>
